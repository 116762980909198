const sample1 =
  "0,3.1,0,0,78,1235609912,2,102,10,0,-1,-1,27,109,16,14,65,23,14,142,70,1,0,0,0,3,1,916068587,0,1,0,0,0,0,1920,1080,1,0,0,0,4217310106|504,93|179,89|132,129|687,160|109,91|202,67|105,144|94,67|182,161|67,138|64,137|98,122|993,106|78,119|67,103|216,90|130,132|149,94|571,132|95,98|110,95|110,117|179,162|42,116|442,75|339,93|151,158|122,116|169,137|114,117|96,107|189,92|59,86|179,122|111,102|105,83|199,112|79,116|433,96|176,104|75,89|209,148|72,152|24,128|89,114|193,143|108,135|98,169|105,110|211,140|106,107|64,87|251,92|186,73|176,78|101,92|95,154|83,142|99,173|136,118|106,116|333,139|106,95|142,107|87,106|97,103|192,101|214,86|97,84|212,134|98,89|107,79|115,82|143,126|72,110|152,113|61,78|213,95";

const sample2 =
  "0,3.1,0,0,53,1555758517,2,102,10,0,-1,-1,27,109,16,14,65,23,14,142,70,1,0,0,0,3,1,916068587,0,1,0,0,0,0,1920,1080,1,0,0,0,4217310106|172,84|70,105|196,148|86,119|65,166|88,180|535,88|227,98|231,82|187,133|123,107|76,115|84,102|180,159|126,218|110,167|79,144|105,126|136,137|116,133|88,114|718,87|211,79|83,63|150,138|87,141|88,138|107,201|82,142|188,121|79,131|86,123|313,103|219,95|75,123|116,156|112,143|151,151|118,108|86,182|93,129|59,83|121,121|89,123|90,95|83,79|175,81|164,124|192,138|252,187|132,209|163,126|276,108";

const sample3 =
  "0,3.1,0,0,42,3235503389,2,102,10,0,-1,-1,27,109,16,14,65,23,14,142,70,1,0,0,0,3,1,916068587,0,1,0,0,0,0,1920,1080,1,0,0,0,4217310106|125,109|84,62|131,129|99,86|118,160|129,171|76,174|126,118|440,72|134,95|119,79|527,110|175,128|96,83|199,157|79,137|31,140|87,116|224,132|113,123|91,173|122,108|321,176|145,177|122,119|309,121|92,148|110,118|151,166|135,124|72,128|65,124|383,145|113,128|68,130|131,123|103,147|154,102|113,91|190,133|63,123|93,131";

module.exports = {
  sample1,
  sample2,
  sample3,
};
